// This command is used by a VSCode plugin to generate imports:
// https://marketplace.visualstudio.com/items?itemName=JayFong.generate-index
// @index('./**/*.svg', (f, _) => `export {default as ${_.pascalCase(f.name) + 'Icon'}} from '${f.path}${f.ext}';`)
export {default as CheckSmallIcon} from './16x16/check-small.svg';
export {default as SmallChevronDownIcon} from './20x20/small-chevron-down.svg';
export {default as SmallChevronUpIcon} from './20x20/small-chevron-up.svg';
export {default as SmallClassIcon} from './20x20/small-class.svg';
export {default as SmallDeleteIcon} from './20x20/small-delete.svg';
export {default as SmallInfoIcon} from './20x20/small-info.svg';
export {default as SmallLinkIcon} from './20x20/small-link.svg';
export {default as SmallPinIcon} from './20x20/small-pin.svg';
export {default as SmallUnpinIcon} from './20x20/small-unpin.svg';
export {default as AllModelsIcon} from './24x24/all-models.svg';
export {default as AnnotationDeleteIcon} from './24x24/annotation-delete.svg';
export {default as AnnotationIcon} from './24x24/annotation.svg';
export {default as ArchiveIcon} from './24x24/archive.svg';
export {default as AreaSizeThresholdIcon} from './24x24/area-size-threshold.svg';
export {default as ArrowLeftIcon} from './24x24/arrow-left.svg';
export {default as ArrowRightIcon} from './24x24/arrow-right.svg';
export {default as CalendarIcon} from './24x24/calendar.svg';
export {default as ChangelogIcon} from './24x24/changelog.svg';
export {default as CheckIcon} from './24x24/check.svg';
export {default as ChevronDownIcon} from './24x24/chevron-down.svg';
export {default as ChevronLeftIcon} from './24x24/chevron-left.svg';
export {default as ChevronRightIcon} from './24x24/chevron-right.svg';
export {default as ChevronUpIcon} from './24x24/chevron-up.svg';
export {default as ClassIcon} from './24x24/class.svg';
export {default as ClassificationIcon} from './24x24/classification.svg';
export {default as CommentIcon} from './24x24/comment.svg';
export {default as ConfidenceThresholdIcon} from './24x24/confidence-threshold.svg';
export {default as CopyIcon} from './24x24/copy.svg';
export {default as CreateSessionIcon} from './24x24/create-session.svg';
export {default as DeleteIcon} from './24x24/delete.svg';
export {default as DownloadIcon} from './24x24/download.svg';
export {default as EditIcon} from './24x24/edit-button.svg';
export {default as ExportIcon} from './24x24/export.svg';
export {default as EyeIcon} from './24x24/eye.svg';
export {default as FilterIcon} from './24x24/filter.svg';
export {default as FlagDeIcon} from './24x24/flag-de.svg';
export {default as FlagEnIcon} from './24x24/flag-en.svg';
export {default as GeneralClassifyOkIcon} from './24x24/general-classify-ok.svg';
export {default as HashIcon} from './24x24/hash.svg';
export {default as HeatmapIcon} from './24x24/heatmap.svg';
export {default as ImageIcon} from './24x24/image.svg';
export {default as InfoIcon} from './24x24/info.svg';
export {default as InstanceIcon} from './24x24/instance.svg';
export {default as LegacyModelIcon} from './24x24/legacy-model.svg';
export {default as MenuIcon} from './24x24/menu.svg';
export {default as MissesIcon} from './24x24/misses.svg';
export {default as ModelIcon} from './24x24/model.svg';
export {default as MonitorIcon} from './24x24/monitor.svg';
export {default as MoreIcon} from './24x24/more.svg';
export {default as NotificationsIcon} from './24x24/notifications.svg';
export {default as ObjectDetectionIcon} from './24x24/object-detection.svg';
export {default as PerspectiveIcon} from './24x24/perspective.svg';
export {default as PinIcon} from './24x24/pin.svg';
export {default as PlayIcon} from './24x24/play.svg';
export {default as ProductionLinesIcon} from './24x24/production-lines.svg';
export {default as ProjectsIcon} from './24x24/projects.svg';
export {default as ReportIcon} from './24x24/report.svg';
export {default as SaveIcon} from './24x24/save.svg';
export {default as SearchIcon} from './24x24/search.svg';
export {default as SettingsIcon} from './24x24/settings.svg';
export {default as ShiftsIcon} from './24x24/shifts.svg';
export {default as SidebarIcon} from './24x24/sidebar.svg';
export {default as SlashIcon} from './24x24/slash.svg';
export {default as SmartTeachSessionIcon} from './24x24/smart-teach-session.svg';
export {default as StatisticsIcon} from './24x24/statistics.svg';
export {default as StatusIcon} from './24x24/status.svg';
export {default as StopCircleIcon} from './24x24/stop-circle.svg';
export {default as SurfaceInspectionIcon} from './24x24/surface-inspection.svg';
export {default as TasksIcon} from './24x24/tasks.svg';
export {default as TeachSessionIcon} from './24x24/teach-session.svg';
export {default as UnpinIcon} from './24x24/unpin.svg';
export {default as UpdateIcon} from './24x24/update.svg';
export {default as UserMinusIcon} from './24x24/user-minus.svg';
export {default as UsersIcon} from './24x24/users.svg';
export {default as MagicWandIcon} from './24x24/magic-wand.svg';
export {default as BookOpenIcon} from './24x24/book-open.svg';
export {default as CloudUploadIcon} from './24x24/cloud-upload.svg';
export {default as CommandIcon} from './24x24/command-icon.svg';
export {default as BackIcon} from './misc/back.svg';
export {default as BrainIcon} from './misc/brain.svg';
export {default as CheckboxCheckedIcon} from './misc/checkbox-checked.svg';
export {default as CheckboxIndeterminateIcon} from './misc/checkbox-indeterminate.svg';
export {default as CheckboxIcon} from './misc/checkbox.svg';
export {default as CorrectIcon} from './misc/correct.svg';
export {default as DefectIcon} from './misc/defect.svg';
export {default as ImageSearchIcon} from './misc/image-search.svg';
export {default as IncorrectIcon} from './misc/incorrect.svg';
export {default as MaddoxAvatarIcon} from './misc/maddox-avatar.svg';
export {default as SmallCheckIcon} from './misc/small-check.svg';
export {default as SnapshotIcon} from './misc/snapshot.svg';
export {default as StatusCircleIcon} from './misc/status-circle.svg';
export {default as PdfIcon} from './old/pdf.svg';
export {default as XlsIcon} from './old/xls.svg';
export {default as ImageLandscapeIcon} from './misc/image-landscape.svg';
export {default as MergeIcon} from './misc/snapshot.svg';
// @endindex
