export type Ability = {
  action: string;
  subject: string;
  inverted?: boolean;
};

export type Abilities = {
  [key: string]: Ability;
};

export const DEFAULT_ABILITIES = {
  startTrainingModel: {action: 'startTraining', subject: 'Model'},
  restartTrainingModel: {action: 'restartTraining', subject: 'Model'},
  publishModel: {action: 'publish', subject: 'Model'},
  evaluateModel: {action: 'evaluate', subject: 'Model'},
  finetuneModel: {action: 'finetune', subject: 'Model'},
  addChartMonitor: {action: 'addChart', subject: 'Monitor'},
  deleteChartMonitor: {action: 'deleteChart', subject: 'Monitor'},
  selectImagesGallery: {action: 'selectImages', subject: 'Gallery'},
  bulkDeleteImagesGallery: {action: 'bulkDeleteImages', subject: 'Gallery'},
  bulkChangeGallery: {action: 'bulkChange', subject: 'Gallery'},
  userImageUploadGallery: {action: 'userImageUpload', subject: 'Gallery'},
  createProductType: {action: 'create', subject: 'ProductType'},
  createTag: {action: 'create', subject: 'Tag'},
  deleteTag: {action: 'delete', subject: 'Tag'},
  deleteImageAnnotation: {action: 'deleteImage', subject: 'Annotation'},
  addTagAnnotation: {action: 'addTag', subject: 'Annotation'},
  removeTagAnnotation: {action: 'removeTag', subject: 'Annotation'},
  changeProductTypeAnnotation: {action: 'changeProductType', subject: 'Annotation'},
  pinAnnotation: {action: 'pin', subject: 'Annotation'},
  unpinAnnotation: {action: 'unpin', subject: 'Annotation'},
  classifyImageAnnotation: {action: 'classifyImage', subject: 'Annotation'},
  markImageAnnotation: {action: 'markImage', subject: 'Annotation'},
  deleteAnnotation: {action: 'delete', subject: 'Annotation'},
  createModel: {action: 'create', subject: 'Model'},
  renameModel: {action: 'rename', subject: 'Model'},
  renameClassModel: {action: 'renameClass', subject: 'Model'},
  recolorClassModel: {action: 'recolorClass', subject: 'Model'},
  addClassModel: {action: 'addClass', subject: 'Model'},
  deleteClassModel: {action: 'deleteClass', subject: 'Model'},
  deleteModel: {action: 'delete', subject: 'Model'},
  reAssingTask: {action: 'reAssing', subject: 'Task'},
  deleteTask: {action: 'delete', subject: 'Task'},
  createShiftSettings: {action: 'createShift', subject: 'Settings'},
  deleteShiftSettings: {action: 'deleteShift', subject: 'Settings'},
  renameProductionLinesSettings: {action: 'renameProductionLines', subject: 'Settings'},
  accountManagement: {action: 'accountManagement', subject: 'User'},
  viewUserSettings: {action: 'viewUserSettings', subject: 'Account'},
  inviteUser: {action: 'inviteUser', subject: 'Account'},
  suspendUser: {action: 'suspendUser', subject: 'Account'},
  changeUserRole: {action: 'changeUserRole', subject: 'Account'},
  viewProjectPage: {action: 'view', subject: 'Project'},
  switchProject: {action: 'switch', subject: 'Project'},
  setDefaultProject: {action: 'setDefault', subject: 'Project'},
  createProject: {action: 'create', subject: 'Project'},
};

export const ANNOTATOR_ABILITIES = {
  startTrainingModel: {action: 'startTraining', subject: 'Model', inverted: true},
  restartTrainingModel: {action: 'restartTraining', subject: 'Model', inverted: true},
  publishModel: {action: 'publish', subject: 'Model', inverted: true},
  createProject: {action: 'create', subject: 'Project', inverted: true},
  inviteUser: {action: 'inviteUser', subject: 'Account', inverted: true},
  suspendUser: {action: 'suspendUser', subject: 'Account', inverted: true},
  changeUserRole: {action: 'changeUserRole', subject: 'Account', inverted: true},
  deleteModel: {action: 'delete', subject: 'Model', inverted: true},
  evaluateModel: {action: 'evaluate', subject: 'Model', inverted: true},
  finetuneModel: {action: 'finetune', subject: 'Model', inverted: true},
};

export const VIEWER_ABILITIES = {
  startTrainingModel: {action: 'startTraining', subject: 'Model', inverted: true},
  restartTrainingModel: {action: 'restartTraining', subject: 'Model', inverted: true},
  publishModel: {action: 'publish', subject: 'Model', inverted: true},
  addChartMonitor: {action: 'addChart', subject: 'Monitor', inverted: true},
  deleteChartMonitor: {action: 'deleteChart', subject: 'Monitor', inverted: true},
  selectImagesGallery: {action: 'selectImages', subject: 'Gallery', inverted: true},
  bulkDeleteImagesGallery: {action: 'bulkDeleteImages', subject: 'Gallery', inverted: true},
  bulkChangeGallery: {action: 'bulkChange', subject: 'Gallery', inverted: true},
  userImageUploadGallery: {action: 'userImageUpload', subject: 'Gallery', inverted: true},
  createProductType: {action: 'create', subject: 'ProductType', inverted: true},
  createTag: {action: 'create', subject: 'Tag', inverted: true},
  deleteTag: {action: 'delete', subject: 'Tag', inverted: true},
  deleteImageAnnotation: {action: 'deleteImage', subject: 'Annotation', inverted: true},
  addTagAnnotation: {action: 'addTag', subject: 'Annotation', inverted: true},
  removeTagAnnotation: {action: 'removeTag', subject: 'Annotation', inverted: true},
  changeProductTypeAnnotation: {action: 'changeProductType', subject: 'Annotation', inverted: true},
  pinAnnotation: {action: 'pin', subject: 'Annotation', inverted: true},
  unpinAnnotation: {action: 'unpin', subject: 'Annotation', inverted: true},
  classifyImageAnnotation: {action: 'classifyImage', subject: 'Annotation', inverted: true},
  markImageAnnotation: {action: 'markImage', subject: 'Annotation', inverted: true},
  deleteAnnotation: {action: 'delete', subject: 'Annotation', inverted: true},
  createModel: {action: 'create', subject: 'Model', inverted: true},
  renameModel: {action: 'rename', subject: 'Model', inverted: true},
  renameClassModel: {action: 'renameClass', subject: 'Model', inverted: true},
  recolorClassModel: {action: 'recolorClass', subject: 'Model', inverted: true},
  addClassModel: {action: 'addClass', subject: 'Model', inverted: true},
  deleteClassModel: {action: 'deleteClass', subject: 'Model', inverted: true},
  deleteModel: {action: 'delete', subject: 'Model', inverted: true},
  reAssingTask: {action: 'reAssing', subject: 'Task', inverted: true},
  deleteTask: {action: 'delete', subject: 'Task', inverted: true},
  createShiftSettings: {action: 'createShift', subject: 'Settings', inverted: true},
  deleteShiftSettings: {action: 'deleteShift', subject: 'Settings', inverted: true},
  renameProductionLinesSettings: {action: 'renameProductionLines', subject: 'Settings', inverted: true},
  accountManagement: {action: 'accountManagement', subject: 'User', inverted: true},
  createProject: {action: 'create', subject: 'Project', inverted: true},
  inviteUser: {action: 'inviteUser', subject: 'Account', inverted: true},
  suspendUser: {action: 'suspendUser', subject: 'Account', inverted: true},
  changeUserRole: {action: 'changeUserRole', subject: 'Account', inverted: true},
  evaluateModel: {action: 'evaluate', subject: 'Model', inverted: true},
  finetuneModel: {action: 'finetune', subject: 'Model', inverted: true},
};

export const TRAINER_ABILITIES = {
  createProject: {action: 'create', subject: 'Project', inverted: true},
  inviteUser: {action: 'inviteUser', subject: 'Account', inverted: true},
  suspendUser: {action: 'suspendUser', subject: 'Account', inverted: true},
  changeUserRole: {action: 'changeUserRole', subject: 'Account', inverted: true},
};
