import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';

import {useUpdateImagesMutation} from '~api/images.mutations';
import {
  confusionMatrixCellIdQueryParam,
  evaluationCellPredictedClassQueryParam,
  evaluationPipelineIdQueryParam,
  finetuneMetricsInterpretationQueryParam,
  imageBrowserAreaSizeQueryParam,
  imageBrowserConfidenceThresholdNotRoundedQueryParam,
  imageBrowserDatasetIdQueryParam,
  imageBrowserHumanLabelQueryParam,
  imageBrowserMachineLabelQueryParam,
  similaritySearchQueryParam,
  useQueryParam,
} from '~utils/routeUtil';
import {ImageFilters, ImageOrderBy, LabelItem, PaginationActionName} from '~redux/types/images';
import {annotatorActions} from '~redux/reducers/annotatorReducer';
import {
  imageDataActions,
  selectImages,
  selectImagesTotalCount,
  selectPageNumber,
  selectPaginationActions,
} from '~redux/reducers/imageReducer';
import {selectCurrentProjectId} from '~redux/reducers/userReducer';
import {decreaseGlobalLoadingIndicator, increaseGlobalLoadingIndicator} from '~redux/actions/globalActions';
import {
  classifyImages,
  deleteImages,
  FetchImagesArgs,
  resetAndFetchAllImagesData,
  updateImagesData,
  updateMeta,
} from '~redux/actions/imageActions';
import {useAppDispatch, useAppSelector} from '~redux/index';

import {parseSimSearchFilter, useImageFilters} from '~components/images/image-overview/imageOverview.hooks';
import {ImageOverviewMode} from '~components/images/utils/image-mode-mixins';
import {useCombinedCheckedItems} from 'src/contexts/checked-items/CombinedCheckedItemsContext';
import {useImagesCache} from 'src/contexts/ImagesCacheContext';
import {useCurrentModel} from 'src/contexts/ModelContext';

export const useConfusionCellFromQueryParams = (newConfusionMatrixCellId?: string): ConfusionCell | undefined => {
  const [confusionMatrixCellId] = useQueryParam(confusionMatrixCellIdQueryParam);
  const [activeCellHumanLabel] = useQueryParam(imageBrowserHumanLabelQueryParam);
  const [activeCellMachineLabel] = useQueryParam(imageBrowserMachineLabelQueryParam);
  const [imageBrowserAreaSize] = useQueryParam(imageBrowserAreaSizeQueryParam);
  const [imageBrowserConfidenceThreshold] = useQueryParam(imageBrowserConfidenceThresholdNotRoundedQueryParam);
  const [imageBrowserDatasetId] = useQueryParam(imageBrowserDatasetIdQueryParam);
  const [finetuneMetricsInterpretation] = useQueryParam(finetuneMetricsInterpretationQueryParam);
  const areaSize = imageBrowserAreaSize ? parseInt(imageBrowserAreaSize as string) : undefined;
  const confidence = imageBrowserConfidenceThreshold
    ? parseFloat(imageBrowserConfidenceThreshold as string)
    : undefined;
  const activeConfusionMatrixCellId = newConfusionMatrixCellId ?? (confusionMatrixCellId as string);
  const pipelineId = activeConfusionMatrixCellId?.split('_')[0];

  if (!activeConfusionMatrixCellId || areaSize === undefined || confidence === undefined || !pipelineId) {
    return;
  }

  return {
    actualClass: activeCellHumanLabel as string,
    predictedClass: activeCellMachineLabel as string,
    areaSize,
    confidence,
    datasetId: imageBrowserDatasetId as Dataset,
    pipelineId,
    interpretation: finetuneMetricsInterpretation as Interpretation,
  };
};

export const useEvaluationCellFromQueryParams = (): EvaluationCell | undefined => {
  const [evaluationPipelineId] = useQueryParam(evaluationPipelineIdQueryParam);
  const [imageBrowserAreaSize] = useQueryParam(imageBrowserAreaSizeQueryParam);
  const [imageBrowserConfidenceThreshold] = useQueryParam(imageBrowserConfidenceThresholdNotRoundedQueryParam);
  const [evaluationCellPredictedClass] = useQueryParam(evaluationCellPredictedClassQueryParam);

  const pipelineId = evaluationPipelineId as string;
  const areaSize = imageBrowserAreaSize ? parseInt(imageBrowserAreaSize as string) : undefined;
  const confidence = imageBrowserConfidenceThreshold
    ? parseFloat(imageBrowserConfidenceThreshold as string)
    : undefined;

  if (!pipelineId || areaSize === undefined || confidence === undefined) {
    return;
  }

  return {
    pipelineId,
    areaSize,
    confidence,
    predictedClass: evaluationCellPredictedClass as string,
  };
};

export const useSimilaritySearchFromQueryParams = (): SimSearchFilter | undefined => {
  const [similaritySearch] = useQueryParam(similaritySearchQueryParam);
  if (!similaritySearch) {
    return;
  }

  const parsedSimSearch = parseSimSearchFilter(similaritySearch as string);
  if (!parsedSimSearch) {
    return;
  }

  const {imageId, searchCoordinate, count} = parsedSimSearch;
  return {
    imageId,
    searchCoordinate,
    count,
  };
};

export function useImagesOverview(
  mode: ImageOverviewMode,
  currentLabels: LabelItem[] | null,
  activeConfusionMatrixCellId?: string,
) {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const images = useAppSelector(selectImages);
  const imageCount = useAppSelector(selectImagesTotalCount);
  const availablePaginationActions = useAppSelector(selectPaginationActions);
  const currentPage = useAppSelector(selectPageNumber);
  const {currentModel} = useCurrentModel();
  const updateAnnotationMutation = useUpdateImagesMutation();
  const confusionCell = useConfusionCellFromQueryParams(activeConfusionMatrixCellId);
  const evaluationCell = useEvaluationCellFromQueryParams();
  const similaritySearch = useSimilaritySearchFromQueryParams();
  const currentProjectId = useAppSelector(selectCurrentProjectId);

  const {loadImagesFromCache} = useImagesCache();
  const [imageFilters, updateImageFilters] = useImageFilters(mode, currentLabels);
  const {
    checkedImages: [checkedItems, actions, checkedItemsNeedRefresh],
  } = useCombinedCheckedItems();

  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);

  const updateImages = async (options?: FetchImagesArgs) => {
    await dispatch(
      updateImagesData({
        withGlobalLoading: true,
        imageFilters: {
          ...imageFilters,
          confusionCell,
          evaluationCell,
          similaritySearch,
          modelId: mode === 'model-defect-book' ? currentModel?.id : undefined,
        },
        ...options,
      }),
    );
    dispatch(imageDataActions.resetOverviewPageNumber());
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const fetchDataAndResetToFirstPage = async () => {
      setIsLoading(true);
      dispatch(imageDataActions.resetImageIndex());
      await dispatch(
        resetAndFetchAllImagesData({
          imageFilters: {
            ...imageFilters,
            confusionCell,
            evaluationCell,
            similaritySearch,
            modelId: mode === 'model-defect-book' ? currentModel?.id : undefined,
          },
        }),
      );
      dispatch(imageDataActions.resetOverviewPageNumber());
      setIsLoading(false);
    };

    const refetchDataForCurrentPage = async () => {
      // Update data of the current page in the background, so that the user can continue working with the cached data.
      // Enable diff check to only update the data if it has changed.
      const cursor = availablePaginationActions?.jump?.cursor;
      if (!cursor) {
        // cannot refetch current page without a cursor, so we need to fetch all data
        // and reset the page number to 1
        fetchDataAndResetToFirstPage();
        return;
      }

      const jumpToCurrentPage = {
        action: {
          name: PaginationActionName.JUMP,
          cursor,
        },
        targetPage: currentPage,
      };

      await dispatch(
        updateImagesData({
          imageFilters: {
            ...imageFilters,
            confusionCell,
            evaluationCell,
            similaritySearch,
            modelId: mode === 'model-defect-book' ? currentModel?.id : undefined,
          },
          withDiffCheck: true,
          paginationActionWithTargetPage: jumpToCurrentPage,
          bustCache: true,
        }),
      );
    };
    if (
      (loadImagesFromCache && mode === 'model-defect-book') ||
      (mode === 'image-browser' &&
        availablePaginationActions?.prev !== null &&
        (confusionCell || evaluationCell || similaritySearch))
    ) {
      // It could be that a user deleted the last annotation of a defect-book image,
      // so we need to refetch the data to ensure that we don't show any images without annotations.
      refetchDataForCurrentPage();
    } else if (!loadImagesFromCache) {
      // Reuse available images data from Redux cache if the image annotator was visited
      // and the user is going back to the same overview page they were on before.
      // See ImagesCacheContext for more details.
      fetchDataAndResetToFirstPage();
    }

    // This should only run once unless the confusion matrix cell changes.
    // data is only retrieved once, either from the Redux cache or by fetching it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, activeConfusionMatrixCellId, currentProjectId]);

  const handlePageChange = async (action: PaginationActionName, targetPage: number) => {
    const paginationAction = availablePaginationActions ? availablePaginationActions[action] : undefined;
    if (paginationAction) {
      const pageOffset = action === PaginationActionName.JUMP ? targetPage - currentPage : undefined;
      updateImageFilters({}, {action: {...paginationAction, pageOffset}, targetPage});
      dispatch(imageDataActions.setOverviewPageNumber(targetPage));
    }
  };

  const handleFilterChange = async (newFilters: Partial<ImageFilters>) => {
    if ('trainingTag' in newFilters) {
      // When a dataset version is selected, the image cannot be modified because
      // a dataset is a "frozen" representation of a certain point in time
      dispatch(annotatorActions.setImageReadOnly(newFilters.trainingTag !== undefined));
    }

    if ('automlPipelineId' in newFilters) {
      // When a snapshot version is selected, the image cannot be modified because
      // a snapshot is a "frozen" representation of a certain point in time
      dispatch(annotatorActions.setImageReadOnly(newFilters.automlPipelineId !== undefined));
    }

    updateImageFilters(newFilters);
    dispatch(imageDataActions.resetOverviewPageNumber());
  };

  const handleBatchDelete = async () => {
    await dispatch(deleteImages(checkedItems.map((imageData) => imageData.id)));
    await updateImages();
    checkedItemsNeedRefresh.current = true;
    actions.clearChecked();
  };

  const handleBatchAnnotationDelete = async () => {
    const imagesToUpdate = checkedItems.map((image) => {
      return {
        imageId: image.id,
        labelsByModel: {
          [currentModel!.id]: [],
        },
      };
    });
    dispatch(increaseGlobalLoadingIndicator());

    updateAnnotationMutation.mutate(imagesToUpdate, {
      onSuccess: async () => {
        await updateImages();
        checkedItemsNeedRefresh.current = true;
      },
      onSettled: () => {
        dispatch(decreaseGlobalLoadingIndicator());
      },
    });
  };

  const handleBatchProductTypeChange = async (productType: string) => {
    const imagesMeta = checkedItems.map((imageData) => {
      return {
        imageId: imageData.id,
        meta: {
          productType,
        },
      };
    });
    await dispatch(updateMeta(imagesMeta));
    await updateImages();
  };

  const handleBatchClassLabelChange = async (labelItem: LabelItem, modelId: string) => {
    await dispatch(classifyImages({images: checkedItems, classLabelItem: labelItem, modelId}));
  };

  function handleImageOrderChange(orderBy: ImageOrderBy) {
    updateImageFilters({orderBy: orderBy});
    dispatch(imageDataActions.resetOverviewPageNumber());
  }

  return {
    images,
    imageCount,
    imageFilters,
    checkedItems,
    actions,
    currentPage,
    handlePageChange,
    updateImages,
    isLoading,
    handleFilterChange,
    handleBatchDelete,
    handleBatchAnnotationDelete,
    handleBatchProductTypeChange,
    handleBatchClassLabelChange,
    handleImageOrderChange,
  };
}
